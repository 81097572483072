.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 60%;
    border-radius: 5px;
    background-color: #6d908d;
}
  
.card label{
    font-size: 32px;
    color: #55cccc;
}

.card p{
    margin-left: 20px;
    margin-right: 20px;
}

.card button{
    color: #333333;
    background-color: #aaa;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    width: 120px;
    height: 40px;
    margin-bottom: 25px;
}

.card button:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    background-color: #bbb;
}
